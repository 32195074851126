import * as React from 'react'

import Page from '../components/Page'
import Container from '../components/Container'
import { SearchBar } from '../components/SearchBar'
import { useState } from 'react'
import { getLatestMediaSeriesAll } from '../services/api'
import { Category } from '../components/Category'

enum Type {
  Newest,
  Favorites,
  Category
}


const ArchivePage = (props: any) => {
  const urlParameter = props['*'];

  return (
    <Page>
      <Container>
        <Category type={'list-archive'} title={'Arkisto'} description="" />
      </Container>
    </Page>
  )
}

export default ArchivePage
